<template>
  <div class="full-page" ref="fullPage" @mousewheel="mouseWheelHandle" @DOMMouseScroll="mouseWheelHandle">
    <div class="full-page-container" ref="fullPageContainer" :style="`transition: all ${duration}s ease-in-out`">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullPageScroll",
  model: {
    prop: "page",
    event: "input"
  },
  data() {
    return {
      fullpage: {
        current: this.page, // 当前的banner位置
        isScrolling: false, // 滚动中
        deltaY: 0,  // 判断是往下还是往上滚
      },
    }
  },
  props: {
    /**
     * 页数，默认4
     */
    size: {
      type: Number | String,
      default: 4,
    },
    /**
     * 动画时长，单位秒，默认1秒
     */
    duration: {
      type: Number | String,
      default: 1,
    },
    page: {
      type: Number,
      default: 1
    },
  },
  methods: {
    next() { // 往下切换
      let len = this.size; // 页面的个数
      if (this.fullpage.current + 1 <= len) { // 如果当前页面编号+1 小于总个数，则可以执行向下滑动
        this.fullpage.current += 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    pre() {// 往上切换
      if (this.fullpage.current - 1 > 0) {  // 如果当前页面编号-1 大于0，则可以执行向下滑动
        this.fullpage.current -= 1;// 页面+1
        this.move(this.fullpage.current);// 执行切换
      }
    },
    move(index) {
      this.fullpage.isScrolling = true;
      this.directToMove(index); //执行滚动
      setTimeout(() => {       //这里的动画是1s执行完，使用setTimeout延迟1s后解锁
        this.fullpage.isScrolling = false;
      }, 1010);
    },
    directToMove(index) {
      let height = this.$refs["fullPage"].clientHeight;  //获取屏幕的宽度
      let scrollPage = this.$refs["fullPageContainer"];    // 获取执行tarnsform的元素
      let scrollHeight; // 计算滚动的告诉，是往上滚还往下滚
      scrollHeight = -(index - 1) * height + "px";
      scrollPage.style.transform = `translate3D(0,${scrollHeight},0)`;
      this.fullpage.current = index;
    },
    // 监听鼠标监听
    mouseWheelHandle(event) {
      // 添加冒泡阻止
      let evt = event || window.event;
      if (evt.stopPropagation) {
        evt.stopPropagation();
      } else {
        evt.returnValue = false;
      }
      if (this.fullpage.isScrolling) { // 判断是否可以滚动
        return false;
      }
      let e = event.originalEvent || event;
      this.fullpage.deltaY = e.deltaY || e.detail; // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next();
      } else if (this.fullpage.deltaY < 0) {
        this.pre();
      }
    },
  },
  watch: {
    "fullpage.current"(val) {
      if (val != this.page) this.$emit("input", val)
    },
  },
}
</script>

<style scoped lang='scss'>
.full-page {
  width: 100%;
  height: 100vh;
}

.full-page-container {
  width: 100%;
  height: 100%;

}

</style>